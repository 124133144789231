<template>
  <ModelCreater :title="title" :loading="isLoading" :errors="errors" :canCreate="canCreate" @create="create">
    <CreateCalendarStyled>
      <InputField :labelWidth="6" class="name">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.name.$model" placeholder="Name" />
        </template>
        <template v-slot:label>
          <span>Name</span>
        </template>
        <template v-slot:errors v-if="$v.name.$dirty">
          <div class="error" v-if="!$v.name.required">Field is required</div>
          <div class="error" v-if="!$v.name.minLength">Field must have at least {{ $v.name.$params.minLength.min }} letters.</div>
        </template>
      </InputField>
      <div class="custom-select">
        <CustomSingleSelect
          class="select-calendar-type"
          track-by="id"
          :options="availableCalendarTypes"
          :selectedOption="selectedCalendarType"
          :shouldNotResetSelectedOption="true"
          :closeOnSelect="true"
          :placeholder="'Select Calendar Type'"
          @selectedFilter="selectCalendarType"
        >
          <template v-slot:customLabelIcon>
            <ShowAsLabel>{{ $tc('type', 1) }}:</ShowAsLabel>
          </template>
        </CustomSingleSelect>
      </div>
      <div class="custom-select">
        <CustomSingleSelect
          class="select-owner-type"
          track-by="id"
          :options="availableOwnerTypes"
          :selectedOption="selectedOwnerType"
          :shouldNotResetSelectedOption="true"
          :closeOnSelect="true"
          :placeholder="'Select Owner Type'"
          @selectedFilter="selectOwnerType"
        >
          <template v-slot:customLabelIcon>
            <ShowAsLabel>{{ $tc('owner', 1) }} {{ $tc('type', 1) }}:</ShowAsLabel>
          </template>
        </CustomSingleSelect>
      </div>
      <div class="custom-select">
        <CustomMultiSelect
          class="select-owner"
          track-by="id"
          openDirection="bottom"
          :options="availableOwnersMapped"
          :value="selectedOwner"
          :shouldNotResetSelectedOption="true"
          :disabled="!selectedOwnerType"
          :closeOnSelect="true"
          :searchable="true"
          :is-loading="availableOwnersIsLoading"
          :placeholder="'Select Owner'"
          :modelName="selectedOwnerTypeId"
          @select="selectOwner"
          @search-change="debouncedfindOwners"
        >
          <template v-slot:customLabelIcon>
            <ShowAsLabel>{{ $tc('owner', 1) }}:</ShowAsLabel>
          </template>
        </CustomMultiSelect>
      </div>
    </CreateCalendarStyled>
  </ModelCreater>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelCreater from '@/components/misc/ModelCreater'
import { InputField } from '@common/components'
import { required, minLength } from 'vuelidate/lib/validators'
import { debounce } from 'vue-debounce'

import { CustomSingleSelect, CustomMultiSelect } from '@common/components'

import USERS_SEARCH_QUERY from '#/graphql/search/users.gql'
import ORGANIZATIONS_SEARCH_QUERY from '#/graphql/search/organizations.gql'
import SUBSIDIARIES_SEARCH_QUERY from '#/graphql/search/subsidiaries.gql'
import CREATE_CALENDAR_MUTATION from '#/graphql/calendar/createCalendar.gql'

const ShowAsLabel = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
`

const CreateCalendarStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
  padding: 1rem;

  .custom-select {
    max-width: 30rem;
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.black};

    .multiselect__content .multiselect__element .multiselect__option.multiselect__option--highlight {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.tableWidgetHightlight};
    }
  }
`

const AVAILABLE_CALENDAR_TYPES = [
  {
    id: 'SHIFT_PLAN',
    label: 'Shift plan',
  },
  {
    id: 'OTHER',
    label: 'Other',
    $isDisabled: true,
  },
]

export default {
  components: {
    CreateCalendarStyled,
    ModelCreater,
    InputField,
    CustomSingleSelect,
    CustomMultiSelect,
    ShowAsLabel,
  },
  data() {
    return {
      name: '',
      alias: '',
      errors: [],
      isLoading: false,
      isCreating: false,
      selectedOwnerType: null,
      selectedOwner: null,
      availableOwners: [],
      availableOwnersIsLoading: false,
      searchQuery: undefined,
      selectedCalendarType: AVAILABLE_CALENDAR_TYPES[0],
      availableCalendarTypes: AVAILABLE_CALENDAR_TYPES,
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
  },
  computed: {
    title() {
      return this.$tc('navigation.archon.createCalendar')
    },
    canCreate() {
      return !this.$v.$invalid && !this.isCreating && Boolean(this.hasOwner)
    },
    hasOwner() {
      return this.selectedOwnerType?.id && this.selectedOwner?.id
    },
    selectedOwnerTypeId() {
      return this.selectOwnerType?.id
    },
    availableOwnerTypes() {
      return [
        {
          id: 'Organization',
          label: 'Organization',
          $isDisabled: true,
        },
        {
          id: 'Subsidiary',
          label: 'Subsidiary',
        },
        {
          id: 'User',
          label: 'User',
        },
      ]
    },
    searchQueryDocument() {
      let doc = USERS_SEARCH_QUERY
      if (this.selectedOwnerType?.id === 'Organization') {
        doc = ORGANIZATIONS_SEARCH_QUERY
      } else if (this.selectedOwnerType?.id === 'Subsidiary') {
        doc = SUBSIDIARIES_SEARCH_QUERY
      }
      return doc
    },
    availableOwnersMapped() {
      return this.availableOwners.map(owner => ({
        id: owner.id,
        label: owner.__typename === 'Subsidiary' ? `${owner.organization.alias} - ${owner.name}` : owner.name,
      }))
    },
  },
  created() {
    this.findOwners = debounce(search => {
      this.debouncedfindOwners(search)
    }, 150)
  },
  methods: {
    async create() {
      const res = await this.$apollo.mutate({
        mutation: CREATE_CALENDAR_MUTATION,
        variables: {
          data: {
            ownerType: this.selectedOwnerType?.id,
            ownerId: this.selectedOwner?.id,
            type: this.selectedCalendarType?.id,
            name: this.name,
          },
        },
      })
      const calendar = res?.data?.createCalendar
      if (calendar?.id) {
        this.$emit('reload')
        this.$router.push({
          name: 'calendar',
          params: {
            id: calendar?.id,
          },
        })
      }
    },
    selectOwnerType(type) {
      this.selectedOwnerType = type
      this.findOwners('')
    },
    selectOwner(owner) {
      this.selectedOwner = owner
    },
    selectCalendarType(type) {
      this.selectedCalendarType = type
    },
    async debouncedfindOwners(search) {
      this.searchQuery = search
      const results = await this.$apollo.query({
        query: this.searchQueryDocument,
        variables: {
          search: this.searchQuery,
        },
      })
      const data = results?.data
      if (!data) {
        return
      }
      const foundOwners = data[Object.keys(data)[0]] || []
      this.availableOwners = foundOwners
    },
  },
}
</script>
