var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelCreater', {
    attrs: {
      "title": _vm.title,
      "loading": _vm.isLoading,
      "errors": _vm.errors,
      "canCreate": _vm.canCreate
    },
    on: {
      "create": _vm.create
    }
  }, [_c('CreateCalendarStyled', [_c('InputField', {
    staticClass: "name",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.name.$model,
            expression: "$v.name.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "Name"
          },
          domProps: {
            "value": _vm.$v.name.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.name, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_c('span', [_vm._v("Name")])];
      },
      proxy: true
    }, _vm.$v.name.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.name.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.name.minLength ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field must have at least " + _vm._s(_vm.$v.name.$params.minLength.min) + " letters.")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('div', {
    staticClass: "custom-select"
  }, [_c('CustomSingleSelect', {
    staticClass: "select-calendar-type",
    attrs: {
      "track-by": "id",
      "options": _vm.availableCalendarTypes,
      "selectedOption": _vm.selectedCalendarType,
      "shouldNotResetSelectedOption": true,
      "closeOnSelect": true,
      "placeholder": 'Select Calendar Type'
    },
    on: {
      "selectedFilter": _vm.selectCalendarType
    },
    scopedSlots: _vm._u([{
      key: "customLabelIcon",
      fn: function fn() {
        return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$tc('type', 1)) + ":")])];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "custom-select"
  }, [_c('CustomSingleSelect', {
    staticClass: "select-owner-type",
    attrs: {
      "track-by": "id",
      "options": _vm.availableOwnerTypes,
      "selectedOption": _vm.selectedOwnerType,
      "shouldNotResetSelectedOption": true,
      "closeOnSelect": true,
      "placeholder": 'Select Owner Type'
    },
    on: {
      "selectedFilter": _vm.selectOwnerType
    },
    scopedSlots: _vm._u([{
      key: "customLabelIcon",
      fn: function fn() {
        return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$tc('owner', 1)) + " " + _vm._s(_vm.$tc('type', 1)) + ":")])];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "custom-select"
  }, [_c('CustomMultiSelect', {
    staticClass: "select-owner",
    attrs: {
      "track-by": "id",
      "openDirection": "bottom",
      "options": _vm.availableOwnersMapped,
      "value": _vm.selectedOwner,
      "shouldNotResetSelectedOption": true,
      "disabled": !_vm.selectedOwnerType,
      "closeOnSelect": true,
      "searchable": true,
      "is-loading": _vm.availableOwnersIsLoading,
      "placeholder": 'Select Owner',
      "modelName": _vm.selectedOwnerTypeId
    },
    on: {
      "select": _vm.selectOwner,
      "search-change": _vm.debouncedfindOwners
    },
    scopedSlots: _vm._u([{
      key: "customLabelIcon",
      fn: function fn() {
        return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$tc('owner', 1)) + ":")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }